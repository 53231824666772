import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Close } from "./../../images/close.svg";
import styles from "./Modal.module.scss";

const Modal = props => {
  const handleClose = () => {
    props.onClose();
  };

  const handlePreventClose = event => {
    // Prevent a regular click from propagating to the parent's onClick, which is handleClose
    event.stopPropagation();
  };

  return (
    <div className={styles.containerOuter} onClick={handleClose}>
      <div className={styles.containerInner} onClick={handlePreventClose}>
        <div className={styles.modal}>
          <div className={styles.close} onClick={handleClose}>
            <Close />
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func
};

export default Modal;
