import { v4 as uuidv4 } from "uuid";
import errorsService from "./../../services/errors";

export const ADD = "app/errors/ADD";
export const HIDE = "app/errors/HIDE";
export const REMOVE = "app/errors/REMOVE";
export const REMOVE_ALL = "app/errors/REMOVE_ALL";

export const add = error => {
  errorsService.makeErrorPropertiesEnumerable(error);

  return {
    type: ADD,
    data: {
      id: uuidv4(),
      details: error
    }
  };
};

export const hide = id => {
  return {
    type: HIDE,
    id
  };
};

export const removeAll = () => {
  return {
    type: REMOVE_ALL
  };
};

export const remove = id => {
  return {
    type: REMOVE,
    id
  };
};
