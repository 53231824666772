import axios from "axios";
import config from "./../../config";

const baseUrl = `${config.WEB_SERVICES.API_URL}/RunningBalances`;

export const getLatest = async () => {
  const url = `${baseUrl}/Latest`;
  const response = await axios.get(url);

  return response.data;
};
