import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxThunk from "redux-thunk";
import rootReducer from "./../rootReducer";

const configureStore = initialState => {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(
        reduxThunk,
        require("redux-immutable-state-invariant").default() // Factory method, so import does not work
      )
    )
  );
};

export default configureStore;
