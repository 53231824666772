import axios from "axios";
import { errorsActions, loadingCountActions } from "./../state/actions";

let authorizationInterceptor;

/**
 * Adds an authorization HTTP header with a bearer JWT to all requests. For userContext objects
 * that change (e.g., a user logs in/out), call removeAuthorizationInterceptor first.
 * @param {*} userContext
 */
export const addAuthorizationInterceptor = userContext => {
  authorizationInterceptor = axios.interceptors.request.use(config => {
    if (userContext.jwt.tokenSerialized) {
      config.headers.authorization = `Bearer ${userContext.jwt.tokenSerialized}`;
    }

    return config;
  });
};

/**
 * Add error logging for response errors. This is only needed for Chrome, which does not let Promise rejections
 * fall through to window.onunhandledrejection when there is a service worker that is caching the JavaScript file
 * that triggers the failed Promise (workbox.precaching.precacheAndRoute(self.__precacheManifest, {})). This does
 * not appear to be a problem in Firefox.
 *
 * Note: This results in double error notifications the service worker is not registered or in other browsers.
 * @param {*} store
 */
export const addErrorHandlingInterceptor = store => {
  axios.interceptors.response.use(null, error => {
    // See https://github.com/axios/axios
    // If (error.response), then the request was made but server responded with a something outside HTTP 200-299.
    // Else if (error.request), then the request was made but there was no server response at all (network error?).
    // Else, there was something wrong with the request.

    store.dispatch(errorsActions.add(error));

    // Must reject, or this error will be swallowed, window.onunhandledrejection will never fire, and the calling
    // code will get an unexpected value from Promise.resolve().
    return Promise.reject(error);
  });
};

export const removeAuthorizationInterceptor = () => {
  if (authorizationInterceptor) {
    axios.interceptors.request.eject(authorizationInterceptor);
  }
};

/**
 * Adds loading Redux actions to all requests and responses
 * @param {*} store
 */
export const addLoadingInterceptors = store => {
  axios.interceptors.request.use(config => {
    store.dispatch(loadingCountActions.increment());

    return config;
  });

  axios.interceptors.response.use(
    response => {
      store.dispatch(loadingCountActions.decrement());

      return response;
    },
    error => {
      store.dispatch(loadingCountActions.decrement());

      // Must reject, or this error will be swallowed, window.onunhandledrejection will never fire, and the calling
      // code will get an unexpected value from Promise.resolve().
      return Promise.reject(error);
    }
  );
};
