import * as loadingCountActions from "./loadingCountActions";

const initialState = 0;

const loadingCount = (state = initialState, action) => {
  switch (action.type) {
    case loadingCountActions.DECREMENT:
      if (state > 1) {
        return state - 1;
      }

      return initialState;
    case loadingCountActions.INCREMENT:
      return state + 1;
    case loadingCountActions.RESET:
      return initialState;
    default:
      return state;
  }
};

export default loadingCount;
