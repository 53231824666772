import runningBalancesService from "./../../services/runningBalances";

export const GET_LATEST_BEGIN = "app/runningBalances/GET_LATEST_BEGIN";
export const GET_LATEST_SUCCESS = "app/runningBalances/GET_LATEST_SUCCESS";
export const GET_LATEST_FAILURE = "app/runningBalances/GET_LATEST_FAILURE";
export const REMOVE = "app/runningBalances/REMOVE";

export const getLatestBegin = () => {
  return {
    type: GET_LATEST_BEGIN
  };
};

export const getLatestSuccess = latest => {
  return {
    type: GET_LATEST_SUCCESS,
    latest
  };
};

export const getLatestFailure = error => {
  return {
    type: GET_LATEST_FAILURE,
    error
  };
};

export const remove = () => {
  return {
    type: REMOVE
  };
};

export const getLatest = () => {
  return async dispatch => {
    dispatch(getLatestBegin());

    try {
      const data = await runningBalancesService.getLatest();

      dispatch(getLatestSuccess(data));
    } catch (error) {
      dispatch(getLatestFailure(error));

      throw error;
    }
  };
};
