import { errorsActions } from "./../../state/actions";

export const makeErrorPropertiesEnumerable = error => {
  if (error && typeof error === "object") {
    Object.defineProperty(error, "message", {
      enumerable: true
    });

    Object.defineProperty(error, "stack", {
      enumerable: true
    });
  }
};

export const configureWindowOnError = store => {
  window.onerror = error => {
    store.dispatch(errorsActions.add(error));

    // Prevent from firing twice
    return true;
  };
};

export const configureWindowOnUnhandledRejection = store => {
  window.onunhandledrejection = error => {
    store.dispatch(errorsActions.add(error.reason));
  };
};
