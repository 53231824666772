import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import config from "./../../config";
import { ignoreErrorStatusCode } from "./../httpHelpers";

export const authenticate = async (email, password) => {
  const url = `${config.WEB_SERVICES.API_URL}/Authentication`;

  const jwtSerialized = await ignoreErrorStatusCode(
    async () => await axios.post(url, { email, password }),
    401
  );

  if (jwtSerialized) {
    Cookies.set(config.SECURITY.AUTHENTICATION.JWT.COOKIE_NAME, jwtSerialized, {
      expires: 7300,
      sameSite: "strict",
      secure: true
    });

    return true;
  }

  return false;
};

export const createUserContext = () => {
  const user = {
    expirationUnixTimeMilliseconds: -1,
    jwt: {
      tokenSerialized: null,
      tokenObject: null
    },

    checkIsAuthenticated: function () {
      return (
        this.jwt.tokenSerialized !== null &&
        this.jwt.tokenSerialized !== undefined &&
        (this.expirationUnixTimeMilliseconds < 0 || // If infinite expiration, the Unix time will be negative or NaN
          this.expirationUnixTimeMilliseconds > Date.now())
      );
    }
  };

  const jwtCookieValue = Cookies.get(
    config.SECURITY.AUTHENTICATION.JWT.COOKIE_NAME
  );

  if (jwtCookieValue) {
    const jwtCookieValueObject = jwt_decode(jwtCookieValue);
    const userObject = createUserObjectFromJwt(
      jwtCookieValue,
      jwtCookieValueObject
    );

    return {
      ...user,
      ...userObject
    };
  } else {
    return { ...user };
  }
};

export const logout = () => {
  Cookies.remove(config.SECURITY.AUTHENTICATION.JWT.COOKIE_NAME);
};

const createUserObjectFromJwt = (tokenSerialized, tokenObject) => {
  const exp = tokenObject["exp"];
  const expirationUnixTime = exp ? Number(exp) : -1; // If infinite expiration, exp will be undefined

  return {
    email: tokenObject["email"],
    expirationUnixTime: expirationUnixTime,
    expirationUnixTimeMilliseconds: expirationUnixTime * 1000,
    jwt: {
      tokenSerialized: tokenSerialized,
      tokenObject: tokenObject
    },
    userId: tokenObject["unique_name"]
  };
};
