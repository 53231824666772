import { format } from "date-fns";
import * as transactionsActions from "./transactionsActions";

const initialState = {
  getResult: null,
  queryResult: []
};

const transactions = (state = initialState, action) => {
  switch (action.type) {
    case transactionsActions.GET_SUCCESS:
      return {
        getResult: mapTransaction(action.getResult),
        queryResult: state.queryResult
      };
    case transactionsActions.QUERY_APPEND_SUCCESS:
      return {
        getResult: state.getResult,
        queryResult: [
          ...state.queryResult,
          ...mapQueryResult(action.queryResult)
        ]
      };
    case transactionsActions.QUERY_SUCCESS:
      return {
        getResult: state.getResult,
        queryResult: mapQueryResult(action.queryResult)
      };
    case transactionsActions.REMOVE_ALL:
      return initialState;
    case transactionsActions.REMOVE_GET_RESULT:
      return {
        getResult: null,
        queryResult: state.queryResult
      };
    case transactionsActions.REMOVE_QUERY_RESULT:
      return {
        getResult: state.getResult,
        queryResult: []
      };
    default:
      return state;
  }
};

const mapQueryResult = queryResult => {
  return queryResult.map(transaction => {
    return mapTransaction(transaction);
  });
};

const mapTransaction = transaction => {
  return {
    ...transaction,
    amountFormatted: Math.round(transaction.amount).toLocaleString(),
    createTimestampFormatted: format(
      transaction.createUnixTimeUtc * 1000,
      "yyyy-MM-dd HH:mm"
    ),
    runningBalanceFormatted: Math.round(
      transaction.runningBalance
    ).toLocaleString()
  };
};

export default transactions;
