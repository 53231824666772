import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Button.module.scss";

const Button = props => {
  const handleClick = () => {
    if (props.isEnabled && props.onClick) {
      props.onClick();
    }
  };

  return (
    <button
      className={classNames(styles.button, {
        [styles.secondary]: props.isSecondary,
        [styles.warning]: props.isWarning,
        "form-spacing-marginTop": props.hasMarginTop,
        "form-spacing-marginRight": props.hasMarginRight,
        "form-disabled": props.isEnabled === false
      })}
      onClick={handleClick}
    >
      {props.text}
    </button>
  );
};

Button.propTypes = {
  isEnabled: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isWarning: PropTypes.bool,
  hasMarginRight: PropTypes.bool,
  hasMarginTop: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string
};

export default Button;
