import axios from "axios";
import config from "./../../config";

const baseUrl = `${config.WEB_SERVICES.API_URL}/Transactions`;

export const get = async transactionId => {
  const url = `${baseUrl}/${transactionId}`;
  const response = await axios.get(url);

  return response.data;
};

export const query = async (query, index, limit) => {
  const url = `${baseUrl}/Query/${index}/${limit}/${query}`;
  const response = await axios.get(url);

  return response.data;
};

export const remove = async transactionId => {
  const url = `${baseUrl}/${transactionId}`;
  const response = await axios.delete(url);

  return response.data;
};

export const save = async (amount, note) => {
  const response = await axios.post(baseUrl, { amount, note });

  return response.data;
};

export const update = async (transactionId, amount, note) => {
  const url = `${baseUrl}/${transactionId}`;
  const response = await axios.put(url, { amount, note });

  return response.data;
};
