import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./ErrorNotifier.module.scss";
import { ReactComponent as ArrowUp } from "./../../images/arrow-up.svg";
import { ReactComponent as ArrowDown } from "./../../images/arrow-down.svg";

const ErrorNotifier = props => {
  const [isStackVisible, setIsStackVisible] = useState(false);

  const toggleDetailsStack = () => {
    setIsStackVisible(!isStackVisible);
  };

  return (
    <div className={styles.container}>
      <div className={styles.errorMessage}>
        <div className={styles.label}>Error</div>
        <div className={styles.message}>{props.message}</div>
        <div className={styles.toggle} onClick={toggleDetailsStack}>
          {!isStackVisible && <ArrowDown />}
          {isStackVisible && <ArrowUp />}
        </div>
      </div>
      {isStackVisible && (
        <div>
          <div className={styles.stack}>{props.stack}</div>
          <div className={styles.stack}>{props.stackComponent}</div>
        </div>
      )}
    </div>
  );
};

ErrorNotifier.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  stack: PropTypes.string,
  stackComponent: PropTypes.string
};

export default ErrorNotifier;
