import * as errorsActions from "./errorsActions";

const initialState = [];

const errors = (state = initialState, action) => {
  switch (action.type) {
    case errorsActions.ADD:
      return [...state, { ...action.data }];
    case errorsActions.HIDE:
      return state.map(error =>
        error.id !== action.id ? error : { ...error, isVisible: false }
      );
    case errorsActions.REMOVE:
      return state.filter(error => error.id !== action.id);
    case errorsActions.REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
};

export default errors;
