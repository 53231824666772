import React, { useEffect, useState } from "react";
import userService from "./../../services/user";
import * as httpInterceptors from "./../../services/httpInterceptors";
import UserContextContext from "./UserContextContext";

const UserContextProvider = props => {
  const [userContext, setUserContext] = useState(
    userService.createUserContext()
  );

  const update = () => {
    const newUserContext = userService.createUserContext();

    setUserContext(newUserContext);
  };

  useEffect(() => {
    httpInterceptors.removeAuthorizationInterceptor();
    httpInterceptors.addAuthorizationInterceptor(userContext);
  }, [userContext]);

  return (
    <UserContextContext.Provider
      value={{ current: userContext, update }}
      {...props}
    />
  );
};

export default UserContextProvider;
