export const DECREMENT = "app/loadingCount/DECREMENT";
export const INCREMENT = "app/loadingCount/INCREMENT";
export const RESET = "app/loadingCount/RESET";

export const decrement = () => {
  return {
    type: DECREMENT
  };
};

export const increment = () => {
  return {
    type: INCREMENT
  };
};

export const reset = () => {
  return {
    type: RESET
  };
};
