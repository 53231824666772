import React, { useState } from "react";
import config from "./../../config";
import userService from "./../../services/user";
import { useUserContext } from "./../../providers/UserContext";
import Modal from "./../Modal";
import BoxActions from "./../BoxActions";
import Button from "./../Button";
import { ReactComponent as AccountIcon } from "./../../images/account.svg";
import styles from "./Account.module.scss";

const Account = () => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const userContext = useUserContext();

  const handleClose = () => {
    setModalIsVisible(false);
  };

  const handleLogout = () => {
    userService.logout();
    window.location.href = "/";
  };

  const handleOpenModal = () => {
    setModalIsVisible(true);
  };

  return (
    <>
      <div className={styles.account} onClick={handleOpenModal}>
        <AccountIcon />
      </div>
      {modalIsVisible && (
        <Modal onClose={handleClose}>
          <div className="form-header">Account</div>
          <div className="form-label">Email</div>
          <div className="form-value">{userContext.current.email}</div>
          <div className="form-label">User ID</div>
          <div className="form-value">{userContext.current.userId}</div>
          <div className="form-label">Build</div>
          <div className="form-value">{config.BUILD_TIMESTAMP}</div>
          <BoxActions>
            <Button isEnabled={true} onClick={handleLogout} text="Logout" />
          </BoxActions>
        </Modal>
      )}
    </>
  );
};

export default Account;
