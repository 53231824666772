import transactionsService from "./../../services/transactions";

export const GET_BEGIN = "app/transactions/GET_BEGIN";
export const GET_SUCCESS = "app/transactions/GET_SUCCESS";
export const GET_FAILURE = "app/transactions/GET_FAILURE";
export const QUERY_APPEND_BEGIN = "app/transactions/QUERY_APPEND_BEGIN";
export const QUERY_APPEND_SUCCESS = "app/transactions/QUERY_APPEND_SUCCESS";
export const QUERY_APPEND_FAILURE = "app/transactions/QUERY_APPEND_FAILURE";
export const QUERY_BEGIN = "app/transactions/QUERY_BEGIN";
export const QUERY_SUCCESS = "app/transactions/QUERY_SUCCESS";
export const QUERY_FAILURE = "app/transactions/QUERY_FAILURE";
export const REMOVE_ALL = "app/transactions/REMOVE_ALL";
export const REMOVE_GET_RESULT = "app/transactions/REMOVE_GET_RESULT";
export const REMOVE_QUERY_RESULT = "app/transactions/REMOVE_QUERY_RESULT";

export const getBegin = () => {
  return {
    type: GET_BEGIN
  };
};

export const getSuccess = result => {
  return {
    type: GET_SUCCESS,
    getResult: result
  };
};

export const getFailure = error => {
  return {
    type: GET_FAILURE,
    error
  };
};

export const queryAppendBegin = () => {
  return {
    type: QUERY_APPEND_BEGIN
  };
};

export const queryAppendSuccess = result => {
  return {
    type: QUERY_APPEND_SUCCESS,
    queryResult: result
  };
};

export const queryAppendFailure = error => {
  return {
    type: QUERY_APPEND_FAILURE,
    error
  };
};

export const queryBegin = () => {
  return {
    type: QUERY_BEGIN
  };
};

export const querySuccess = result => {
  return {
    type: QUERY_SUCCESS,
    queryResult: result
  };
};

export const queryFailure = error => {
  return {
    type: QUERY_FAILURE,
    error
  };
};

export const removeAll = () => {
  return {
    type: REMOVE_ALL
  };
};

export const removeGetResult = () => {
  return {
    type: REMOVE_GET_RESULT
  };
};

export const removeQueryResult = () => {
  return {
    type: REMOVE_QUERY_RESULT
  };
};

export const get = transactionId => {
  return async dispatch => {
    dispatch(getBegin());

    try {
      const data = await transactionsService.get(transactionId);

      dispatch(getSuccess(data));
    } catch (error) {
      dispatch(getFailure(error));

      throw error;
    }
  };
};

export const query = (query, index, limit) => {
  return async dispatch => {
    dispatch(queryBegin());

    try {
      const data = await transactionsService.query(query, index, limit);

      dispatch(querySuccess(data));
    } catch (error) {
      dispatch(queryFailure(error));

      throw error;
    }
  };
};

export const queryAppend = (query, index, limit) => {
  return async dispatch => {
    dispatch(queryAppendBegin());

    try {
      const data = await transactionsService.query(query, index, limit);

      dispatch(queryAppendSuccess(data));
    } catch (error) {
      dispatch(queryAppendFailure(error));

      throw error;
    }
  };
};
