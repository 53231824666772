import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "./state/store";
import * as httpInterceptors from "./services/httpInterceptors";
import errorsService from "./services/errors";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { UserContextProvider } from "./providers/UserContext";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorsNotifier from "./components/ErrorsNotifier";
import App from "./components/App";
import "./styles/index.scss";

const store = configureStore();

errorsService.configureWindowOnError(store);
errorsService.configureWindowOnUnhandledRejection(store);

httpInterceptors.addLoadingInterceptors(store);

ReactDOM.render(
  <ReduxProvider store={store}>
    <ErrorBoundary>
      <UserContextProvider>
        <ErrorsNotifier />
        <App />
      </UserContextProvider>
    </ErrorBoundary>
  </ReduxProvider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
