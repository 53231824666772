import React from "react";
import { useSelector } from "react-redux";
import { loadingCountSelectors } from "./../../state/selectors";
import styles from "./LoadingIndicator.module.scss";

const LoadingIndicator = () => {
  const loadingCount = useSelector(state =>
    loadingCountSelectors.select(state)
  );

  if (loadingCount === 0) {
    return null;
  }

  return <div className={styles.loading} />;
};

export default LoadingIndicator;
