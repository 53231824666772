import React, { Suspense } from "react";
import { BrowserRouter, NavLink, Route, Switch } from "react-router-dom";
import { useUserContext } from "./../../providers/UserContext";
import LoadingIndicator from "./../LoadingIndicator";
import Account from "./../Account";
import { ReactComponent as Home } from "./../../images/home.svg";
import { ReactComponent as Statistics } from "./../../images/statistics.svg";
import { ReactComponent as History } from "./../../images/history.svg";
import "./../../styles/app.scss";

const PageHome = React.lazy(() => import("./../PageHome"));
const PageStatistics = React.lazy(() => import("./../PageStatistics"));
const PageHistory = React.lazy(() => import("./../PageHistory"));
const PageLogin = React.lazy(() => import("./../PageLogin"));

const App = () => {
  const userContext = useUserContext();
  const isAuthenticated = userContext.current.checkIsAuthenticated();

  return (
    <Suspense fallback={<></>}>
      <LoadingIndicator />
      {!isAuthenticated && <PageLogin />}
      {isAuthenticated && (
        <BrowserRouter>
          <div className="app">
            <div id="app-body" className="app-body">
              <Account />
              <Switch>
                <Route path="/" exact component={PageHome} />
                <Route path="/statistics" exact component={PageStatistics} />
                <Route path="/history" exact component={PageHistory} />
                <Route
                  path="/history/:index?/:limit?/:query?"
                  exact
                  component={PageHistory}
                />
              </Switch>
            </div>
            <div className="app-footer">
              <NavLink
                to="/statistics"
                exact
                className="app-footer-button"
                activeClassName="app-footer-button--active"
              >
                <Statistics />
              </NavLink>
              <NavLink
                to="/"
                exact
                className="app-footer-button"
                activeClassName="app-footer-button--active"
              >
                <Home />
              </NavLink>
              <NavLink
                to="/history"
                className="app-footer-button"
                activeClassName="app-footer-button--active"
              >
                <History />
              </NavLink>
            </div>
          </div>
        </BrowserRouter>
      )}
    </Suspense>
  );
};

export default App;
