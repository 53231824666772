export const handleExists = async requestFunction => {
  try {
    const response = await requestFunction();

    if (response.status === 200) {
      return true;
    }

    return false;
  } catch (error) {
    if (error && error.response && error.response.status === 404) {
      return false;
    }

    throw error;
  }
};

export const ignoreErrorStatusCode = async (
  requestFunction,
  errorStatusCode,
  defaultResponseData = null
) => {
  try {
    const response = await requestFunction();

    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status === errorStatusCode) {
      return defaultResponseData;
    }

    throw error;
  }
};
