import * as balanceActions from "./runningBalancesActions";

const initialState = {
  latest: null
};

const runningBalances = (state = initialState, action) => {
  switch (action.type) {
    case balanceActions.GET_LATEST_SUCCESS:
      return {
        latest: action.latest,
        latestFormatted: Math.round(action.latest).toLocaleString()
      };
    case balanceActions.REMOVE:
      return initialState;
    default:
      return state;
  }
};

export default runningBalances;
