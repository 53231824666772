const config = {
  BUILD_TIMESTAMP: process.env.REACT_APP_BUILD_TIMESTAMP,
  ENVIRONMENT: {
    IS_DEVELOPMENT: process.env.NODE_ENV === "development",
    IS_PRODUCTION: process.env.NODE_ENV === "production"
  },
  INPUT: {
    BLUR_DELAY_MILLISECONDS: 200 // Less than 200 is too fast
  },
  PAGES: {
    HOME: {
      COUNTDOWN_SECONDS: 600
    },
    HISTORY: {
      TRANSACTIONS_LIMIT_DEFAULT: 20
    }
  },
  PUBLIC_URL: process.env.PUBLIC_URL,
  SECURITY: {
    AUTHENTICATION: {
      JWT: {
        COOKIE_NAME:
          process.env.REACT_APP_SECURITY_AUTHENTICATION_JWT_COOKIE_NAME
      }
    }
  },
  WEB_SERVICES: {
    API_URL: process.env.REACT_APP_WEB_SERVICES_API_URL
  }
};

export default config;
