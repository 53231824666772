import { combineReducers } from "redux";
import * as reducers from "./reducers";
import { appActions } from "./actions";

const appReducer = combineReducers({
  ...reducers
});

const rootReducer = (state, action) => {
  // See https://stackoverflow.com/a/35641992/1408642
  if (action.type === appActions.RESET) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
