import React from "react";
import { useSelector } from "react-redux";
import { errorsSelectors } from "./../../state/selectors";
import ErrorNotifier from "./../ErrorNotifier";
import styles from "./ErrorsNotifier.module.scss";

const ErrorsNotifier = () => {
  const errors = useSelector(state => errorsSelectors.select(state));

  return (
    <>
      {errors && errors.length > 0 && (
        <div className={styles.container}>
          {errors.map(error => (
            <ErrorNotifier
              key={error.id}
              id={error.id}
              message={error.details?.message}
              stack={error.details?.stack}
              stackComponent={error.details?.stackComponent}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ErrorsNotifier;
